<template lang="pug">
tr.template-list-item
  td: router-link(:to = 'template.id.toString()' append) {{ template.id }}
  td: router-link(:to = 'template.id.toString()' append)  {{ template.templateName }}
  td {{ classifierByName('templateType', template.templateType.code).human }}
  td
    span.badge.badge-success(v-if='template.active') {{ $t('common:active') }}
    span.badge.badge-dark(v-else) {{ $t('common:inactive') }}
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'template-list-item',

  props: {
    template: {
      type: Object,
      required: true
    }
  },

  computed: mapGetters('classifiers', ['classifierByName'])
}
</script>
