<template lang="pug">
.template-list.animated.fadeIn
  .card
    .card-header
      | {{ $t('title') }}
      router-link.float-right(
        to = 'new'
        append
      ) {{ $t('newTemplate') }}
    .card-block
      fi-table(
        :fields      = 'fields'
        :items       = 'sortClassifiers'
        loader       = 'templates:fetch'
        :empty-label = '$t("notFound")'
        :sort.sync   = 'searchParams.sort'
        responsive
      )
        template(#default = '{ items }')
          template-list-item(
            v-for     = 'template in items'
            :key      = 'template.id'
            :template = 'template'
          )
      fi-pagination(
        v-if    = 'templatesPages > 1'
        :pages  = 'templatesPages'
        v-model = 'pagination.page'
      )
</template>


<script>
import { mapState, mapActions } from 'vuex'
import TemplateListItem from './TemplateListItem.vue'
import FiTable from '@/components/FiTable'
import debounce from 'lodash/debounce'
import FiPagination from '@/components/FiPagination'

export default {
  name: 'template-list',

  components: {
    FiPagination,
    FiTable,
    TemplateListItem
  },

  i18nOptions: {},

  data: () => ({
    searchParams: {
      sort: 'id,asc'
    },
    pagination: {
      page: 0,
      size: 25
    }
  }),

  computed: {
    ...mapState('templates', ['templatesList', 'templatesPages']),
    fields () {
      return [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'name',
          label: this.$t('name'),
          sort: 'templateName'
        },
        {
          key: 'type',
          label: this.$t('type')
        },
        {
          key: 'status',
          label: this.$t('status')
        }
      ]
    },
    sortClassifiers () {
      const list = JSON.parse(JSON.stringify(this.templatesList))
      const sortList = list.sort(function (a, b) {
        const nameA = a.templateName
        const nameB = b.templateName
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      return sortList
    }
  },

  watch: {
    searchParams: {
      handler () {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    'pagination.page' () {
      this.search()
    }
  },

  created () {
    this.loadTemplatesList({ query: { ...this.searchParams, ...this.pagination } })
  },

  methods: {
    ...mapActions('templates', ['loadTemplatesList']),
    search: debounce(
      function () {
        this.loadTemplatesList({ query: { ...this.searchParams, ...this.pagination } })
      }, 250
    )
  }
}
</script>


<i18n>
en:
  title:       "Templates"
  newTemplate: "+ New Template"
  name:        "Name"
  type:        "Type"
  status:      "Status"
et:
  title:       "Templates"
  newTemplate: "+ New Template"
  name:        "Name"
  type:        "Type"
  status:      "Status"
ru:
  title:       "Templates"
  newTemplate: "+ New Template"
  name:        "Name"
  type:        "Type"
  status:      "Status"
</i18n>
